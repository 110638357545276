
import React, { useState, useEffect } from "react";
import { Col, Row, Button, message, Upload } from "antd";
import { ChromePicker } from "react-color";
import {
  BgColorsOutlined,
  UndoOutlined,
  SaveOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { useMutation } from "@apollo/client";
import {
  updateBrandingColor,
  updateBrandingImage,
  updateBrandingImageSmall
} from "../../queries/brandingQueries";

import axios from "axios";
import { ConfigurationMoreSettings } from "../../../ConfigurationMoreSettings";

import localStorageService from '../../../../../services/localStorageService';
import { type } from 'os';
const companyId = localStorageService.get('companyId');
const companyBrand = JSON.parse(localStorageService.get('companyDetails'));


const getBase64 = (img, type) => {
  return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.addEventListener('load', async () => {
          try {
              let _imageBlobsorage = await axios({
                  method: "POST",
                  url: process.env.REACT_APP_FUNCTION_IMAGEBLOBSTORAGE,
                  data: {
                      imageBase64: reader.result,
                      type: type
                  },
              });
              resolve(_imageBlobsorage.data.url); // Resuelve con la URL de la imagen
             
          } catch (error) {
              reject(error); // En caso de error en la petición HTTP, rechaza la promesa
          }
      });
      reader.onerror = error => reject(error);
      reader.readAsDataURL(img);
  });
};



const beforeUpload = (file) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error(
      "Solo se permite la carga de archivos en formato JPG/PNG file!"
    );
  }

  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("La imagen debe ser de máximo 2MB!");
  }
  return isJpgOrPng && isLt2M;
};

export const BrandingScreen = () => {

  const [updBrandingColor] = useMutation(updateBrandingColor, { fetchPolicy: 'no-cache' });
  const [updBrandingImage] = useMutation(updateBrandingImage, { fetchPolicy: 'no-cache' });
  const [updBrandingImageSmall] = useMutation(updateBrandingImageSmall, { fetchPolicy: 'no-cache' });
  const [type, setType] = useState("bigBrand");


  const [companyColor, setCompanyColor] = useState("#fff");
  const [newCompanyColor, setNewCompanyColor] = useState("#fff");
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imageUrlBigBrand, setImageUrlBigBrand] = useState(companyBrand.bigBrand);
  const [imageUrlSmallBrand, setImageUrlSmallBrand] = useState(companyBrand.smallBrand);
  const [imageUrl, setImageUrl] = useState();

  useEffect(() => {
    console.log(companyBrand)
    if (companyBrand && companyBrand.primaryColor)
      setCompanyColor(companyBrand.primaryColor)
    setNewCompanyColor(companyBrand.primaryColor)
  }, []);
  const UpdateBranding = async (urlImage) => {
    const reponseImageBranding = await updBrandingImage({
      variables: {
        bigBrand: urlImage,
        id: companyId
      }
    });
    if (reponseImageBranding.data.update_companies.affected_rows > 0) {
      companyBrand.bigBrand = urlImage;
      localStorageService.set('companyDetails', JSON.stringify(companyBrand))
    }
  };
  const UpdateBrandingSmall = async (urlImage) => {
    const reponseImageBranding = await updBrandingImageSmall({
      variables: {
        smallBrand: urlImage,
        id: companyId
      }
    });
    if (reponseImageBranding.data.update_companies.affected_rows > 0) {
      companyBrand.smallBrand = urlImage;
      localStorageService.set('companyDetails', JSON.stringify(companyBrand))
    }
  };

  const handleChangeSmall = async (info) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      try {
        const imageUrlSmallBrand = await getBase64(info.file.originFileObj, "smallBrand");
        setLoading(false);
        setImageUrlSmallBrand(imageUrlSmallBrand); // Establece el URL de la imagen con el resultado de la promesa
        UpdateBrandingSmall(imageUrlSmallBrand);
        setType("smallBrand");
      } catch (error) {
        setLoading(false);
      }
    }
  };

  const handleClick = () => {
    setDisplayColorPicker(true);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  const handleChange = async (info) => {

    if (info.file.status === "uploading") {

      setLoading(true);
      try {

        const imageUrl = await getBase64(info.file.originFileObj);
        setLoading(false);
        setImageUrl(imageUrl); // Establece el URL de la imagen con el resultado de la promesa
        UpdateBranding(imageUrl);
      } catch (error) {
        setLoading(false);
      }
    }
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  const popover = {
    position: "absolute",
    zIndex: "2",
  };

  const cover = {
    position: "fixed",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px",
  };

  const resetCompanyColor = () => {
    setNewCompanyColor(companyColor);
  };

  const saveCompanyColor = async () => {
    const res = await updBrandingColor({
      variables: {
        primaryColor: newCompanyColor,
        id: companyId,
      },
    });

    if (res.data.update_companies.affected_rows > 0) {
      companyBrand.primaryColor = newCompanyColor;
      localStorageService.set("companyDetails", JSON.stringify(companyBrand));
      document.documentElement.style.setProperty(
        "--primaryColor",
        companyBrand.primaryColor
      );
      setCompanyColor(newCompanyColor);
    }
  };
  return (
    <Col className='children-inner-container-with-bg'>
      <ConfigurationMoreSettings />
      <br />
      <br />
      <h3>Colores</h3>
      <Row gutter={[25, 25]}>
        <Col xs={24} sm={12} xl={12}>
          <h4>Color actual</h4>
          <div
            style={{ minHeight: "100px", backgroundColor: companyColor }}
          ></div>
        </Col>
        <Col xs={24} sm={12} xl={12}>
          <h4>Nuevo color</h4>
          <div
            style={{
              minHeight: "100px",
              backgroundColor: newCompanyColor,
              display: "flex",
              alignItems: "center",
            }}
          >
            <Button
              style={{ position: "relative", margin: "0 auto" }}
              onClick={handleClick}
              shape="circle"
              icon={<BgColorsOutlined />}
            />
            {displayColorPicker ? (
              <div style={popover}>
                <div style={cover} onClick={handleClose} />
                <ChromePicker
                  onChangeComplete={(color) => {
                    setNewCompanyColor(color.hex);
                  }}
                  disableAlpha={true}
                  color={newCompanyColor}
                />
              </div>
            ) : null}

            {newCompanyColor !== companyColor ? (
              <Button
                style={{ position: "relative", margin: "0 auto" }}
                onClick={resetCompanyColor}
                shape="circle"
                icon={<UndoOutlined />}
              />
            ) : null}

            {newCompanyColor !== companyColor ? (
              <Button
                style={{ position: "relative", margin: "0 auto" }}
                onClick={saveCompanyColor}
                shape="circle"
                icon={<SaveOutlined />}
              />
            ) : null}
          </div>
        </Col>
      </Row>

      <br />
      <br />
      <h3>Imágenes</h3>
      <Row gutter={[25, 25]}>
        <Col xs={24} sm={12} xl={12}>
          <h4>Logo completo</h4>
          <Upload
            name="avatar"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            beforeUpload={beforeUpload}
            onChange={handleChange}
          >
            {imageUrl ? (
              <img
                src={imageUrl}
                alt="avatar"
                style={{
                  width: "100%",
                }}
              />
            ) : (
              uploadButton
            )}
          </Upload>
        </Col>
        <Col xs={24} sm={12} xl={12}>
          <h4>Identificador</h4>
          <div>
            <img
              src={companyBrand.smallBrand}
              style={{ maxWidth: "100px", height: "auto" }}
            />
          </div>
        </Col>
      </Row>
    </Col>
  );
};

