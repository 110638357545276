import React from "react";
import {
  Grid,
  Paper,
  Typography,
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import Lines from "../../../../../../images/seguimiento.png";
import { ArrowForwardIos } from "@mui/icons-material";
import { useFormContext } from "react-hook-form";
import { useFormData } from "../../../../../../context/FormContext";
import DeleteIcon from '@material-ui/icons/Delete';

const TabContentSeven = () => {

  const { formData, updateFormData } = useFormData();

  // Manejar el cambio del checkbox
  const handleCheckChange = (event) => {
    const { checked } = event.target;
    updateFormData({ linesDetail: checked }); // Actualizar el estado del checkbox
  };

  // Manejar cambios en los campos de texto para cada línea
  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const updatedLines = [...formData.detailLines]; // Copiar las líneas actuales

    // Actualizar la línea específica
    updatedLines[index] = { ...updatedLines[index], [name]: value };
    updateFormData({ detailLines: updatedLines });
  };

  // Agregar una nueva línea
  const addLine = () => {
    const newLine = { productCode: "", productName: "", quantityOrdered: "" };
    const updatedLines = [...formData.detailLines, newLine]; // Añadir una nueva línea
    updateFormData({ detailLines: updatedLines });
  };

  // Eliminar una línea específica
  const removeLine = (index) => {
    const updatedLines = formData.detailLines.filter((_, i) => i !== index); // Filtrar la línea a eliminar
    updateFormData({ detailLines: updatedLines });
  };


  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={8} className="option-content">
        <Paper
          elevation={0}
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",  // Cambié "center" por "flex-start" para que los elementos se alineen a la izquierda
            justifyContent: "flex-start",  // Alinea los elementos al principio
            width: "100%", // Permite que el Paper ocupe todo el ancho disponible
            marginTop: "30px", // Puedes mantener este margen si quieres separación
          }}
        >
          <Typography
            variant="h6"
            gutterBottom
            align="center"
            sx={{
              width: "100%",  // Asegura que el título ocupe todo el ancho
              fontWeight: "bold",
            }}
          >
            Detalle Lineas
          </Typography>
          <br /><br />
          <Box
            sx={{
              width: "100%", 
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start", 
              marginTop: "20px"
            }}
          >
          </Box>

          {/* Checkbox para habilitar el formulario de detalles de líneas */}
          <FormGroup sx={{ width: "100%"}}>  
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.linesDetail || false}
                  onChange={handleCheckChange}
                />
              }
              label="Habilitar detalle de líneas"
              sx={{
                justifyContent: "center",
                alignItems: "center", 
              }}
            />
          </FormGroup>

          {/* Mostrar los campos solo si el checkbox está activado */}
          {formData.linesDetail && (
            <Box
              sx={{
                width: "100%", 
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",  
                marginTop: "20px",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={addLine}
                disabled={formData.isUpdate}
                sx={{ width: "100%", marginBottom: "20px" }} 
              >
                Agregar Línea
              </Button>

              {/* Renderizar las líneas de detalle */}
              {formData.detailLines.map((line, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginBottom: "15px",
                    width: "100%",  
                  }}
                >
                  <TextField
                    label="Código de Producto *"
                    name="productCode"
                    value={line.productCode || ""}
                    disabled={formData.isUpdate}
                    onChange={(e) => handleInputChange(index, e)}
                    fullWidth
                    margin="normal"
                    size="small"
                    required
                    sx={{ marginRight: 2 }}
                    error={formData.isNullFied === true && !formData.productCode}
                    helperText={formData.isNullFied === true && !formData.productCode ? "Este campo es requerido" : ""}
                  />

                  <TextField
                    label="Nombre del Producto *"
                    name="productName"
                    disabled={formData.isUpdate}
                    value={line.productName || ""}
                    onChange={(e) => handleInputChange(index, e)}
                    fullWidth
                    margin="normal"
                    size="small"
                    required
                    sx={{ marginRight: 2 }}
                    error={formData.isNullFied === true && !formData.productName}
                    helperText={formData.isNullFied === true && !formData.productName ? "Este campo es requerido" : ""}
                  />

                  <TextField
                    label="Cantidad Ordenada *"
                    name="quantityOrdered"
                    disabled={formData.isUpdate}
                    value={line.quantityOrdered || ""}
                    onChange={(e) => handleInputChange(index, e)}
                    fullWidth
                    margin="normal"
                    size="small"
                    required
                    sx={{ marginRight: 2, maxWidth: 80 }}
                  />

                  {/* Botón para eliminar la línea */}
                  <Button
                    variant="outlined"
                    color="primary"
                    disabled={formData.isUpdate}
                    onClick={() => removeLine(index)}
                    sx={{ height: "36px" }}
                  >
                    <DeleteIcon></DeleteIcon>
                  </Button>
                </Box>
              ))}
            </Box>
          )}
        </Paper>
      </Grid>

      <Grid
        className="option-content"
        item
        xs={12}
        md={4}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box sx={{ maxWidth: "100%", textAlign: "center" }}>
          <img
            src={Lines}
            alt="Lines"
            style={{
              maxWidth: "100%",
              maxHeight: "500px", // Puedes ajustar este valor según tus necesidades
            }}
            className="option-contentImage"

          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default TabContentSeven;