import React from "react";
import {
  Grid,
  Paper,
  Typography,
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import Commitment from "../../../../../../images/en-linea.png";
import { ArrowForwardIos } from "@mui/icons-material";
import { useFormData } from "../../../../../../context/FormContext";


const TabContentFour = () => {

  const { formData, updateFormData } = useFormData();
  const handleCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    // Actualizamos el estado de 'enableDeliveryWindow' en formData
    updateFormData({ enableDeliveryWindow: isChecked });

    // Si el checkbox se desmarca, también limpiamos las fechas
    if (!isChecked) {
      updateFormData({ startDate: "", endDate: "" });
    }
  };
 
  return (
    <Grid container spacing={2}>
      <Grid
        className="option-content"
        item
        xs={12}
        md={6}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box sx={{ maxWidth: "100%", textAlign: "center" }}>
          <img
            src={Commitment}
            alt="Commitment"
            style={{
              maxWidth: "100%",
              maxHeight: "400px",
            }}
            className="option-contentImage"
          />
        </Box>
      </Grid>

      <Grid item xs={12} md={6} className="option-content">
        <Paper
          elevation={0}
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            maxWidth: "100%",
            marginTop: "30px",
          }}
        >
          <Typography
            variant="h6"
            gutterBottom
            align="center"
            sx={{
              width: "100%",
              fontWeight: "bold",
            }}
          >
            Fechas
          </Typography>
          <Box
            sx={{
              width: "100%", 
              maxWidth: "100%", 
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <FormGroup sx={{ width: "70%" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.enableDeliveryWindow || false} 
                    onChange={handleCheckboxChange}
                  />
                }
                label="Habilitar ventana de entrega"
              />
            </FormGroup>
            {formData.enableDeliveryWindow && (
              <>
                <TextField
                  label="Fecha inicial"
                  type="datetime-local"
                  value={formData.expectedDeliveryStartTime || ""}
                  onChange={(e) => updateFormData({ expectedDeliveryStartTime: e.target.value })}
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{ width: "70%" }}
                  error={formData.isNullFied === true && !formData.expectedDeliveryStartTime}
                  helperText={formData.isNullFied === true && !formData.expectedDeliveryStartTime ? "Este campo es requerido" : ""}
                />

                <TextField
                  label="Fecha final"
                  type="datetime-local"
                  value={formData.expectedDeliveryEndTime || ""}
                  onChange={(e) => updateFormData({ expectedDeliveryEndTime: e.target.value })}
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{ width: "70%" }}
                  error={formData.isNullFied === true && !formData.expectedDeliveryEndTime}
                  helperText={formData.isNullFied === true && !formData.expectedDeliveryEndTime ? "Este campo es requerido" : ""}
                  
                />
              </>
            )}

          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default TabContentFour;