import React from "react";
import { useEffect } from "react";
import { Pagination, Stack, Select, MenuItem, Box } from "@mui/material";
import LoadCard from "./CardCarga";
import "../../stylesTMS/styles-tms.css";
import { ReactComponent as FilterIcon } from "../../../../../../../images/filter_alt_off_FILL0_wght400_GRAD0_opsz24.svg";

const PaginatedComponent = ({
  totalRecords,
  currentPage,
  setCurrentPage,
  setPageData,
  handleSelectLoad,
  pageData,
  selecteLoadCarga,
  tmsLoad,
  setSelecteLoadCarga,
  setSelecteShipping,
  setSelectedShippingId,
  setShowDetail,
  setIsDetailExpanded,
  areAllSelected,
}) => {

  const [recordsPerPage, setRecordsPerPage] = React.useState(30);
  const [totalPages, setTotalPages] = React.useState(0);
  const handleChangePage = (event, newPage) => {
    setSelecteLoadCarga(null);
    setSelecteShipping(null);
    setSelectedShippingId(null);
    setShowDetail(false);
    setIsDetailExpanded(false);
    setCurrentPage(newPage);
    tmsLoad("", "", recordsPerPage, newPage);
  };

  const handleFilterClear = () => {
    setSelecteLoadCarga(null);
    setSelecteShipping(null);
    setSelectedShippingId(null);
    setShowDetail(false);
    setIsDetailExpanded(false);
  }


  const handleChangeRecordsPerPage = (event) => {
    setRecordsPerPage(event.target.value);
    setCurrentPage(1);
    tmsLoad("", "", event.target.value, 1);
  };

  useEffect(() => {
    setTotalPages(Math.ceil(totalRecords / recordsPerPage));
  }, [totalRecords, recordsPerPage]);


  return (
    <>
      <div className="my-pagination-controls">
        <h1 className="titulo">Listado de cargas {selecteLoadCarga?.LoadId}</h1>
        <div className="pagination-container-padre">
          <Stack spacing={2} className="my-pagination" direction="row">
            <div className="pagination">
              <Pagination
                count={totalPages}
                page={currentPage}
                onChange={handleChangePage}
                color="primary"
                classes={{ ul: "my-pagination-ul" }}
              />
            </div>


            <div className="pagination-select">

              <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>

                <Select
                  value={recordsPerPage}
                  onChange={handleChangeRecordsPerPage}
                  displayEmpty
                  className="my-select"
                  inputProps={{ "aria-label": "Without label" }}
                  fullWidth
                  sx={{
                    width: "70px",
                    height: "30px",
                    marginTop: "20px"


                  }}
                >

                <MenuItem value={30} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>30</MenuItem>
                <MenuItem value={50} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>50</MenuItem>
                <MenuItem value={100} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>100</MenuItem>


              </Select>


            </Box>


        </div>
      </Stack>
      <div
        className={`button-filter-icon-cargas ${selecteLoadCarga ? "" : "hidden"
          }`}
        onClick={handleFilterClear}
      >
        <FilterIcon className="filter-icon-cargas" />
      </div>
    </div >
      </div >
  <div className="load-cards-container">
    {pageData.map((carga) => (
      <LoadCard
        key={carga.$id}
        carga={carga}
        onClick={() => handleSelectLoad(carga)}
        isSelected={
          areAllSelected || carga.LoadId === selecteLoadCarga?.LoadId
        }
      />
    ))}
  </div>
    </>
  );
};

export default PaginatedComponent;
