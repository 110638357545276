import React, { useEffect, useState } from "react";
import {
  Grid,
  Paper,
  Typography,
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Autocomplete,
  Switch
} from "@mui/material";
import ClientData from "../../../../../../images/servicio-al-cliente.png";
import { ArrowForwardIos } from "@mui/icons-material";
import { useFormData } from "../../../../../../context/FormContext";
import { useLazyQuery } from "@apollo/client";
import { fetchClients, fetchPaymentMethods, fetchSectors, queryValidateNIT } from "../../../../queries/uploadData";
import localStorageService from "../../../../../../services/localStorageService";
import '../styles/HeaderUploadScreenV2.css';

const companyId = localStorageService.get("companyId");


const TabContentTwo = () => {

  const [getClient, dataClient] = useLazyQuery(fetchClients, {
    fetchPolicy: "no-cache",
  });
  const [getPaymentMethods, dataPaymentMethods] = useLazyQuery(
    fetchPaymentMethods,
    {
      fetchPolicy: "no-cache",
    }
  );
  const [getSectors, dataSectors] = useLazyQuery(fetchSectors, {
    fetchPolicy: "no-cache",
  });

  

  const { formData, updateFormData } = useFormData();
  console.log("Informacion que tiene: formData", formData);
  
  const fieldsToCheck = ["custEmail", "custFullName", "custIdentificationNumber", "custPhoneNumber"];
  const areSpecificFieldsEmpty = fieldsToCheck.every(key => formData[key] === "" || formData[key] === null);
  console.log("¿Los campos específicos están vacíos?", areSpecificFieldsEmpty);
  
  
  console.log("Información que tiene isFormDataEmpty:", areSpecificFieldsEmpty);

  const [refresh, setRefresh] = useState(false);

  const marcarComoNulo = () => {
    updateFormData((prev) => ({
      ...prev,
      isNullFied: true,
    }));
    
    // Forzar re-renderización
    setRefresh((prev) => !prev);
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
  
    updateFormData({
      [name]: type === "checkbox" ? checked : value,
    });
  
    if (name === "isNewClient") {
      if (checked) {
        updateFormData({
          custIdentificationNumber: "",
          custFullName: "",
          custPhoneNumber: "",
          custEmail: "",
          typeOfPerson: "",
          state: "",
          city: "",
          address: "",
          addressComplement: "",
          destination: "",
          sectorId: "",
        });
      }
      return; 
    }
  
    // Si se modifica "custIdentificationNumber", buscar el cliente
    if (name === "custIdentificationNumber") {
      const selectedClientNit = value.split("-")[0];
      const selectedClient = dataClient?.data?.clients?.find(
        (client) => client.nit === selectedClientNit
      );
      const sector = dataSectors?.data?.sectors?.find(
        (sector) => sector.id === selectedClient?.clientAddresses?.[0]?.sectorId
      );
  
      if (selectedClient) {
        updateFormData({
          custFullName: selectedClient.name,
          custPhoneNumber:
            selectedClient.clientAddresses?.[0]?.phoneNumber ||
            selectedClient.clientAddresses?.[0]?.cellPhoneNumber ||
            "",
          custEmail: selectedClient.email || "",
          typeOfPerson:
            selectedClient.personType === "organization"
              ? "Persona Jurídica"
              : "Persona Natural",
          state: selectedClient.clientAddresses?.[0]?.state || "",
          city: selectedClient.clientAddresses?.[0]?.city || "",
          address: selectedClient.clientAddresses?.[0]?.address || "",
          addressComplement:
            selectedClient.clientAddresses?.[0]?.addressComplement || "",
          destination:
            selectedClient.clientAddresses?.[0]?.lat +
            "-" +
            selectedClient.clientAddresses?.[0]?.lng || "",
          sectorId: sector ? sector.id : "",
        });
      } else {
        updateFormData({
          custFullName: "",
          custPhoneNumber: "",
          custEmail: "",
          typeOfPerson: "",
        });
      }
    }
  };
  
  useEffect(() => {
    const loadInitData = async () => {
      getClient({
        variables: {
          companyId,
        },
      });
      getPaymentMethods({
        variables: {
          companyId,
        },
      });
      getSectors({
        variables: {
          companyId,
        },
      });
    };
    loadInitData();
  }, []);

  const clients = dataClient?.data?.clients || [];

  const [isNullFied, setIsNullFied] = useState(
    localStorage.getItem("nullFied") === "true"
  );

  useEffect(() => {
    const checkStorage = () => {
      setIsNullFied(localStorage.getItem("nullFied") === "true");
    };

    window.addEventListener("storage", checkStorage); // 🔄 Escucha cambios en localStorage

    return () => {
      window.removeEventListener("storage", checkStorage);
    };
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid
        className="option-content"
        item
        xs={12}
        md={6}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box sx={{ maxWidth: "100%", textAlign: "center" }}>
          <img
            src={ClientData}
            alt="ClientData"
            
            className="option-content-image-client"
          />
        </Box>
      </Grid>

      <Grid item xs={12} md={6} className="option-content">
        <Typography
          variant="h6"
          gutterBottom
          align="center"
          sx={{
            width: "100%",
            fontWeight: "bold",
          }}
          className="option-content-title-client"
        >
          Cliente
        </Typography>
        <Paper
          elevation={0}
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            maxWidth: "100%",
            marginTop: "15px",
            maxHeight: "450px",
            overflowY: "scroll",
            overflowX: "hidden",
          }}
        >
          <Box
            sx={{
              width: "100%",
              maxWidth: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
          {/* {areSpecificFieldsEmpty && ( */}
              <Box
                sx={{
                  width: "70%",
                  display: "flex",
                  justifyContent: "flex-start",
                  marginTop: "0px",

                }}
              >
                <FormGroup>
                <FormControlLabel
                    control={
                      <Switch
                        checked={formData.isNewClient}
                        onChange={handleInputChange}
                        name="isNewClient"
                      />
                    }
                    label="Cliente nuevo"
                    sx={{
                      marginLeft: -1,
                      marginTop: "0px",
                      "& .MuiFormControlLabel-label": {
                        marginLeft: 1,
                      },
                    }}
                  />
                {/* {reSpecificFieldsEmpty && ( 
                  
                )}  */}
                </FormGroup>
              </Box>
          {/* )}  */}

       
            <FormControl
              fullWidth
              margin="normal"
              sx={{ width: "70%" }}
              variant="outlined"
              required
            >
              {formData.isNewClient ? (
                //Inpunt si es un cliente nuevo Angelo Martinez 03/03/2025
                  <TextField
                    id="identificacion-cliente"
                    label="Identificación Cliente"
                    variant="outlined"
                    size="small"               
                    name="custIdentificationNumber"
                    required
                    value={formData.custIdentificationNumber || ""}
                    onChange={handleInputChange}
                    error={formData.isNullFied === true && !formData.custIdentificationNumber}
                    helperText={formData.isNullFied === true && !formData.custIdentificationNumber ? "Este campo es requerido" : ""}

                    sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-root.MuiInputBase-sizeSmall": {
                        height: "39px", 
                    }
                  }}
                  />
                ) : (
                /*
                Angelo Martinez - Comentado para probar el Autocomplete< 03/03/2025>
                <Select
                  labelId="identificacion-cliente-label"
                  id="identificacion-cliente"
                  label="Identificación cliente"
                  disabled={formData.isUpdate}
                  value={formData.custIdentificationNumber || ""}
                  onChange={handleInputChange}
                  name="custIdentificationNumber"
                  sx={{ height: "50px" }}
                >
                  {dataClient?.data?.clients?.length > 0 ? (
                    dataClient.data.clients.slice() // Crear una copia del array
                    .sort((a, b) => parseInt(a.nit, 10) - parseInt(b.nit, 10)) // Ordenar numéricamente
                    .map((client) => (
                      <MenuItem key={client.nit} value={client.nit}>
                        {`${client.nit}-${client.name}`}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem value="">No hay clientes disponibles</MenuItem>
                  )}
                </Select> 
                */
                

                //Funcionando con Autocomplete Angelo amartinez 03/03/2025
                  <Autocomplete
                  id="identificacion-cliente"
                  options={clients}
                  getOptionLabel={(option) => `${option.nit} - ${option.name}`}
                  value={clients.find((client) => client.nit === formData.custIdentificationNumber) || null}
                  onChange={(event, newValue) => {
                    handleInputChange({
                      target: { name: 'custIdentificationNumber', value: newValue ? newValue.nit : '' }
                    });
                  }}
                  sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-root": {
                        height: "39px",  // Asegurar que la altura sea igual a ReactSelect
                        borderRadius: "4px",  // Asegurar esquinas iguales
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Identificación Cliente"
                      disabled={formData.isUpdate}
                      size="small"  
                      required
                      error={formData.isNullFied === true && !formData.custIdentificationNumber}  
                      helperText={formData.isNullFied === true && !formData.custIdentificationNumber ? "Este campo es requerido" : ""}   
                              
                    />
                  )}
                />
                )
              }
            </FormControl>

            <FormControl
              fullWidth
              margin="normal"
              sx={{ width: "70%" }}
              variant="outlined"
              required
            >
            <TextField
              label="Nombre del cliente"
              variant="outlined"
              required
              size="small"               
              disabled={formData.isUpdate}
              value={formData.custFullName} 
              onChange={handleInputChange} 
              name="custFullName"
              error={formData.isNullFied === true && !formData.custFullName}
              helperText={formData.isNullFied === true && !formData.custFullName ? "Este campo es requerido" : ""}
            />
            </FormControl>

            <FormControl
              fullWidth
              margin="normal"
              sx={{ width: "70%" }}
              variant="outlined"
              required
            >
            <TextField
              label="Celular cliente"
              variant="outlined"
              size="small"
              required
              value={formData.custPhoneNumber}
              onChange={handleInputChange}
              name="custPhoneNumber"
              error={formData.isNullFied === true && !formData.custPhoneNumber}
              helperText={formData.isNullFied === true && !formData.custPhoneNumber ? "Este campo es requerido" : ""}
            />
            </FormControl>

            <FormControl
              fullWidth
              margin="normal"
              sx={{ width: "70%" }}
              variant="outlined"
              required
            >
            <TextField
              label="Email cliente"
              variant="outlined"
              size="small"
              disabled={formData.isUpdate}
              required
              value={formData.custEmail}
              onChange={handleInputChange}
              name="custEmail"
              error={formData.isNullFied === true && !formData.custEmail}
              helperText={formData.isNullFied === true && !formData.custEmail ? "Este campo es requerido" : ""}
            />
            </FormControl>

            <FormControl
              fullWidth
              margin="normal"
              sx={{ width: "70%" }}
              variant="outlined"
              required
              size="small"
              error={formData.isNullFied === true && !formData.typeOfPerson}
              helperText={formData.isNullFied === true && !formData.typeOfPerson ? "Este campo es requerido" : ""}
            >
              <InputLabel id="tipo-de-persona-label">Tipo de persona</InputLabel>
              <Select
                labelId="tipo-de-persona-label"
                id="tipo-de-persona"
                label="Tipo de persona"
                value={formData.typeOfPerson}
                onChange={handleInputChange}
                size="small"
                name="typeOfPerson"
                sx={{
                  width: "100%",
                  "& .MuiOutlinedInput-root": {
                      height: "39px", 
                      borderRadius: "4px", 
                  }
                }}

              >
                <MenuItem value="Persona Juridica">Persona Juridica</MenuItem>
                <MenuItem value="Persona Natural">Persona Natural</MenuItem>
              </Select>
              </FormControl>
              {/* Requiere cobro checkbox */}
              <Box sx={{ width: "70%", display: "flex", justifyContent: "flex-start" }}>
                <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={formData.paymentOrCashOnDeliveryRequired}
                      onChange={handleInputChange}
                      name="paymentOrCashOnDeliveryRequired"
                    />
                  }
                  label="Requiere cobro"
                  sx={{
                    marginLeft: -1,
                    "& .MuiFormControlLabel-label": {
                      marginLeft: 1,
                    },
                  }}
                />
              </FormGroup>
            </Box>
            
            {/* Campos adicionales solo si "Requiere cobro" está marcado */}
            {formData.paymentOrCashOnDeliveryRequired && (
              <>
                <FormControl
                  fullWidth
                  margin="normal"
                  sx={{ width: "70%" }}
                  variant="outlined"
                  required
                >
                  <InputLabel id="payment-method-label">Método de pago</InputLabel>
                  <Select
                    labelId="payment-method-label"
                    id="payment-method"
                    label="Método de pago"
                    disabled={formData.isUpdate}
                    value={formData.paymentMethod || ""}
                    onChange={handleInputChange}
                    name="paymentMethod"
                    sx={{ 
                      height: "50px",
                      width: "100%",
                      "& .MuiOutlinedInput-root": {
                      height: "39px", 
                      borderRadius: "4px", 
                  }
                    }}
                    error={formData.isNullFied === true && !formData.paymentMethod}
                    helperText={formData.isNullFied === true && !formData.paymentMethod ? "Este campo es requerido" : ""}

                  >
                    {/* Aquí mapeamos los métodos de pago desde la respuesta de la API */}
                    {dataPaymentMethods?.data?.paymentMethods?.length > 0 ? (
                      dataPaymentMethods.data.paymentMethods.map((method) => (
                        <MenuItem key={method.id} value={method.id}>
                          {method.name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value="">No hay métodos de pago disponibles</MenuItem>
                    )}
                  </Select>
                </FormControl>

                <FormControl
                  fullWidth
                  margin="normal"
                  sx={{ width: "70%" }}
                  variant="outlined"
                  required
                >
                <TextField
                  label="Monto total"
                  variant="outlined"
                  disabled={formData.isUpdate}
                  required
                  size="small"
                  value={formData.totalOrderAmount || ''}
                  onChange={handleInputChange}
                  name="totalOrderAmount"
                  error={formData.isNullFied === true && !formData.totalOrderAmount}
                  helperText={formData.isNullFied === true && !formData.totalOrderAmount ? "Este campo es requerido" : ""}
                />
                </FormControl>
              </>
            )}
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default TabContentTwo;