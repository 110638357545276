import React, { useEffect, useRef, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CachedIcon from '@material-ui/icons/Cached';
import { IconButton, Button, Box, TextField, Select, Divider } from '@material-ui/core';
import { Autocomplete } from '@mui/material';
import Search from 'antd/lib/transfer/search';
import MenuIcon from '@material-ui/icons/Menu';
import moment from 'moment';
import { getRoles, insertUserQuery, getUsers, updateUser, fetchDistributionCenters, fetchWorkshopLocation, cleanDistrutionCenter, updateDistributionCenters, deleteUserWithoutRoute, getUsersByName, updateUserInsertAutomaticPassword, activeUser, checkIfIdentificationNumberEmailExists, checkIfIdentificationNumberEmailExistsForUpdate } from '../queries/usersApi';
import { useApolloClient, useLazyQuery, useMutation } from '@apollo/client';
import Swal from 'sweetalert2';
import localStorageService from '../../../../services/localStorageService';
import ReactSelect from 'react-select';
import SearchIcon from '@material-ui/icons/Search';
import bcrypt from "bcryptjs";
import axios from 'axios';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import CancelIcon from '@mui/icons-material/Cancel';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';



// import '../styles/StyleDistributionCenter.css';



export default function UsersConfiguration() {
    const [userList, setUserList] = useState([]);
    const [roleList, setRoleList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 9;
    const [position, setPosition] = useState({ top: 0, left: 0 });
    const [refreshKey, setRefreshKey] = useState(0);
    const [name, setName] = useState('');
    const [identification, setIdentification] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [address, setAddress] = useState('');
    const [role, setRole] = useState('');
    const [isEmailVerified, setIsEmailVerified] = useState(false);

    const [roleDisplayName, setRoleDisplayName] = useState('');
    const [roleId, setRoleId] = useState('');
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [selectedWorkshopLocation, setSelectedWorkshopLocation] = useState([]);
    const [WorkshopLocationList, setWorkshopLocationList] = useState([]);
    const [selectedOptionRole, setSelectedOptionRole] = useState(null);
    const [selectedWorkshopLocationList, setSelectedWorkshopLocationList] = useState(null);

    const companyId = localStorageService.get('companyId');
    const [distributionCenter, setDistributionCenter] = useState('');
    const [enabled, setEnabled] = useState(false);
    const [searchedName, setSearchedName] = useState('');
    const apolloClient = useApolloClient();
    const [workshopLocation, setWorkshopLocation] = useState('');

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = userList.slice(indexOfFirstItem, indexOfLastItem);
    const [getUsersList] = useLazyQuery(getUsers, {
        fetchPolicy: "no-cache",
    });
    const [getUsersListByName] = useLazyQuery(getUsersByName, {
        fetchPolicy: "no-cache",
    });
    const [InsertPasswordAutomaticUser] = useMutation(
        updateUserInsertAutomaticPassword,
        { fetchPolicy: "no-cache" }
    );
    const [getRoleList, { data: dataRole }] = useLazyQuery(getRoles, {
        fetchPolicy: "no-cache",
    });
    const [insertUser] = useMutation(insertUserQuery, {
        refetchQueries: [
            {
                query: getUsers,
            },
        ],
        onCompleted: () => {
            getUserList();
        },
    });
    const [udpUser] = useMutation(updateUser, {
        fetchPolicy: "no-cache",
        onCompleted: () => {
            getUserList();
        }
    });
    const [activeUserById] = useMutation(activeUser, {
        fetchPolicy: "no-cache",
        onCompleted: () => {
            getUserList();
        }
    });
    const [getDistributionCenters, { data }] = useLazyQuery(
        fetchDistributionCenters,
        {
            variables: { companyId },
            fetchPolicy: 'no-cache',
        }
    );

    const CustomDropdownIcon = () => (
        <ExpandMoreIcon style={{ color: "rgb(95 99 104 / 44%)", fontSize: "40px", width: "25px", height: "20px" }} />
    );
    // const [getWorkshopLocation, { dataWorkhop }] = useLazyQuery(
    //     fetchWorkshopLocation,
    //     {
    //         variables: { companyId },
    //         fetchPolicy: 'no-cache',
    //     }
    // );

    const [getWorkshopLocation, dataWorkhop] = useLazyQuery(fetchWorkshopLocation, {
        fetchPolicy: "no-cache",
    });

    const [cleanDistCenter] = useMutation(cleanDistrutionCenter, {
        fetchPolicy: "no-cache",
    });
    const [updateDistCenter] = useMutation(updateDistributionCenters, {
        fetchPolicy: "no-cache",
        onCompleted: () => {
            getUserList();
        }
    });
    const [deleteUserMutation] = useMutation(deleteUserWithoutRoute, {
        fetchPolicy: "no-cache",
        onCompleted: () => {
            getUserList();
        }
    });

    useEffect(() => {
        getDistributionCenters(); // Disparamos la consulta
    }, [getDistributionCenters]);

    useEffect(() => {
        getWorkshopLocation(); // Disparamos la consulta
    }, [getWorkshopLocation]);

    const handleNextPage = () => {
        setCurrentPage(prevPage => prevPage + 1);
    };
    const handlePreviousPage = () => {
        setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
    };
    const refreshList = () => {
        getUserList();
    };

    const [userSelected, setUserSelected] = useState(null);
    const [idUser, setIdUser] = useState(-1);
    const [openOptions, setOpen] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [openCreate, setOpenCreate] = useState(false);
    const menuRef = useRef(null);

    const renewDistributionCenters = async (id, distributionCenterId) => {
        await cleanDistCenter({ variables: { id } }).then((res) => {
        });
        const distributionCenters = distributionCenterId.map((item) => {
            return {
                userId: id,
                distributionCenterId: item.value,
            };
        });

        await updateDistCenter({
            variables: { distributionCenters },
        }).then((res) => {
        });
    };

    const handleSubmit = () => {
        const userData = {
            name,
            identification,
            email,
            phone,
            address,
            role,
            isEmailVerified,
            distributionCenter,
            enabled,
            //
            workshopLocation
        };
        handleCloseCreate();
        insertUserFunction(userData);
        nullFields();
    };

    const handleUpdate = () => {
        const userData = {
            idUser,
            name,
            identification,
            email,
            phone,
            address,
            role,
            isEmailVerified,
            distributionCenter,
            enabled,
            workshopLocation
        };
        handleCloseEdit();
        updateUserById(userData);
        nullFields();
    };
    const handleOpen = (event) => {
        const windowWidth = window.innerWidth;
        const windowHeight = window.innerHeight;

        setPosition({
            top: Math.min(event.pageY, windowHeight), // ajusta el límite inferior
            left: Math.min(event.pageX, windowWidth - 150), // ajusta el límite derecho
        });
        setOpen(true);
    };
    const handleOpenEdit = (event) => {
        getWorkshopLocation({ variables: { companyId } });
        setOpenEdit(true);
    };


    const handleOpenCreate = (event) => {
        getWorkshopLocation({ variables: { companyId } });
        setOpenCreate(true);
    };

    const handleClose = () => setOpen(false);
    const handleCloseEdit = () => setOpenEdit(false);
    const handleCloseCreate = () => setOpenCreate(false);

    const getUserList = async () => {
        const responseGetUsers = await getUsersList();

        // Crear una lista temporal con los datos procesados
        const newUserList = responseGetUsers.data.users.map((item) => {
            let userDistributionCenter = item.userDeliveryCenters.map((center) => {
                const distributionCenter = center?.distributionCenter?.[0];
                return {
                    value: center.distributionCenterId,
                    label: distributionCenter?.name || 'Nombre desconocido',
                };
            });

            let distributionCenterId = item.userDeliveryCenters.map((dcenter) => {
                return dcenter.distributionCenterId;
            });

            return {
                key: item.id,
                id: item.id,
                displayName: item.displayName,
                email: item.email,
                identificationNumber: item.identificationNumber,
                phoneNumber: item.phoneNumber,
                roleId: item.roleId,
                address: item.address,
                enabled: item.enabled,
                emailVerified: item.emailVerified,
                distributionCenterId: distributionCenterId,
                distributionCenter: userDistributionCenter,
                createdAt: item.createdAt,
                roleName: item.role.label,
                hasRoutes: item.routes_aggregate?.aggregate?.count > 0 ? false : true,
                workshopLocation: item.workshopLocation
            };
        });

        setUserList(newUserList);
    };
    const getRolesList = async () => {
        const responseRoleList = await getRoleList();
        setRoleList(responseRoleList.data.roles)
    }
    const updateUserFunction = async (userData) => {
        try {
            await udpUser({
                variables: {
                    id: userData.idUser,
                    displayName: userData.name,
                    email: userData.email,
                    identificationNumber: userData.identification,
                    phoneNumber: userData.phone,
                    roleId: userData.role,
                    address: userData.address,
                    enabled: userData.enabled,
                    distributionCenter: userData.distributionCenter,
                    isEmailVerified: userData.isEmailVerified,
                    workshopLocation: userData.workshopLocation
                },
            })
                .then((res) => {
                    if (res.data.update_users.affected_rows > 0) {
                        renewDistributionCenters(userData.idUser, selectedOptions);

                        Swal.fire({
                            title: "Actualización exitosa",
                            text: "Usuario actualizado exitosamente!",
                            icon: "success",
                            confirmButtonText: "Cerrar",
                        })
                    }
                });
        } catch (error) {
        }
    };
    const updateUserById = (userData) => {
        Swal.fire({
            title: "Estás seguro?",
            text: "El registro sera editado",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sí, editar"
        }).then((result) => {
            if (result.isConfirmed) {
                updateUserFunction(userData);
            }
        });
    }


    useEffect(() => {
        if (roleList.length === 0) {
            getRolesList();
        }
    }, [roleList]);

    useEffect(() => {
        if (userList.length === 0) {
            getUserList();
        }
    }, [userList]);

    const handleDelete = (record) => {
        Swal.fire({
            title: `¿Desea eliminar el usuario "${record.displayName}"?`,
            text: "¡Este proceso es irreversible!",
            showDenyButton: true,
            confirmButtonText: "Eliminar",
            denyButtonText: "Cancelar",
        }).then(async (result) => {
            if (result.isConfirmed) {
                const deleteUserWithDistributionCenters = await cleanDistCenter({
                    variables: { id: record.id },
                    fetchPolicy: "no-cache",
                });
                const res = await deleteUserMutation({
                    variables: { id: record.id },
                    fetchPolicy: "no-cache",
                });
                if (res.data.delete_users.affected_rows > 0 && deleteUserWithDistributionCenters.data.delete_userDistributionCenters.affected_rows > 0) {
                    Swal.fire({
                        title: "Eliminado",
                        text: `El usuario "${record.displayName}" fue eliminado.`,
                        icon: "success",
                        confirmButtonText: "Cerrar",
                    });
                }
            }
            nullFields();
        });
    };

    const insertUserFunction = async (userData) => {
        try {
            const responseInsertUser = await insertUser({
                variables: {
                    address: userData.address,
                    email: userData.email,
                    identificationNumber: userData.identification,
                    phoneNumber: userData.phone,
                    roleId: userData.role,
                    emailVerified: userData.isEmailVerified,
                    displayName: userData.name,
                    distributionCenterId: userData.distributionCenter,
                    enabled: userData.enabled,
                    //
                    workshopLocation: userData.workshopLocation

                },
            });

            if (responseInsertUser?.data?.insert_users?.returning.length > 0) {
                const { id } = responseInsertUser.data.insert_users.returning[0];

                await renewDistributionCenters(id, selectedOptions);

                userData.isEmailVerified === true
                    ? Swal.fire({
                        title: "Enviar Verificación al Correo Electronico",
                        text: "Se Enviara al Correo Electronico Registrado una Notificacion de Verificación!",
                        icon: "success",
                        confirmButtonText: "Aceptar",
                        willClose: () => {
                            const data = {
                                id: userData.id,
                                email: userData.email,
                                displayName: userData.name,
                                roleId: userData.role,
                            };
                            handleSendEmailVerification(data);
                        },
                    })
                    : Swal.fire({
                        title: "Contraseña Automatica",
                        text: "Su Contraseña es el Numero de Documento Registrado en el sistema!",
                        icon: "success",
                        confirmButtonText: "Aceptar",
                        willClose: () => {
                            const data = {
                                id,
                                identificationNumber: userData.identification,
                                email: userData.email
                            };
                            passwordIdentificationNumber(data);
                        },
                    });
            } else {
                console.error('No se insertó el usuario.');
            }
            nullFields();
        } catch (error) {
            console.error('Error al insertar el usuario:', error);
        }
    }

    const nullFields = () => {
        setSelectedOptions([]);
        setSelectedOptionRole(null);
        setName('');
        setIdentification('');
        setEmail('');
        setPhone('');
        setAddress('');
        setRole('');
        setIsEmailVerified(false);
        setEnabled(false);
    }

    useEffect(() => {
        if (userSelected) {
            const userRole = roleList.find((roleItem) => roleItem.id === userSelected.roleId);
            if (userRole) {
                setRoleId(userRole.id);
                const displayName = userRole ? userRole.name : 'Rol no encontrado';
                setRoleDisplayName(displayName);
            }
            setName(userSelected.displayName);
            setIdentification(userSelected.identificationNumber);
            setEmail(userSelected.email);
            setPhone(userSelected.phoneNumber);
            setAddress(userSelected.address);
            setRole(userSelected.roleId);
            setIsEmailVerified(userSelected.emailVerified);
            setEnabled(userSelected.enabled);
            setSelectedOptions(userSelected.distributionCenter);
            setSelectedOptionRole({ value: userSelected.roleId, label: userSelected.roleName });
        }
    }, [userSelected, roleList]);

    const handleChange = (selectedOption) => {
        if (Array.isArray(selectedOption) && selectedOption.length > 0) {
            setSelectedOptions(selectedOption);
            setDistributionCenter(selectedOption[0].value);
        } else {
            setSelectedOptions([]);
            setDistributionCenter('');
        }
    };

    const handleChangeRoles = (selectedOption) => {
        setSelectedOptionRole(selectedOption);
        setRole(selectedOption.value);
    };
    
    const handleChangeWorkshopLocation = (event, newValue) => {
        if (newValue) {
            setSelectedWorkshopLocationList(newValue);
            setWorkshopLocation(newValue); 
        } else {
            setSelectedWorkshopLocationList(null);
            setWorkshopLocation("");
        }
    };
    

    const transformedRoles = dataRole?.roles?.map((role) => ({
        value: role.id,
        label: role.label,
    })) || [];

    const transformedCenters = data?.distributionCenters?.map((center) => ({
        value: center.id,
        label: center.name,
    })) || [];




    const transformedworkshopLocation = dataWorkhop?.workshopLocation?.map((locationWorkshop) => ({
        value: locationWorkshop.id,
        label: locationWorkshop.location,
    })) || [];


    useEffect(() => {
        if (dataWorkhop) {
            const locations = dataWorkhop?.data?.workshopLocation?.map(loc => loc.location) || [];
            setWorkshopLocationList(locations);

        } else {
        }
    }, [dataWorkhop]);

    const handleSearchChange = (e) => {
        setSearchedName(e.target.value);
    };

    const searchByName = async () => {
        try {
            const nameWithWildcards = `%${searchedName}%`;

            await getUsersListByName({
                variables: {
                    displayName: nameWithWildcards,
                },
            }).then((response => {
                if (response.data.users.length > 0) {
                    const newUserList = response.data.users.map((item) => {
                        let userDistributionCenter = item.userDeliveryCenters.map((center) => {
                            const distributionCenter = center?.distributionCenter?.[0];
                            return {
                                value: center.distributionCenterId,
                                label: distributionCenter?.name || 'Nombre desconocido',
                            };
                        });

                        let distributionCenterId = item.userDeliveryCenters.map((dcenter) => {
                            return dcenter.distributionCenterId;
                        });

                        return {
                            key: item.id,
                            id: item.id,
                            displayName: item.displayName,
                            email: item.email,
                            identificationNumber: item.identificationNumber,
                            phoneNumber: item.phoneNumber,
                            roleId: item.roleId,
                            address: item.address,
                            enabled: item.enabled,
                            emailVerified: item.emailVerified,
                            distributionCenterId: distributionCenterId,
                            distributionCenter: userDistributionCenter,
                            createdAt: item.createdAt,
                            roleName: item.role.label,
                            hasRoutes: item.routes_aggregate?.aggregate?.count > 0 ? false : true,
                        };
                    });
                    setUserList(newUserList);
                }
                else {
                    getUserList();
                }
                setCurrentPage(1);
            }));

        } catch (error) {
        }
    };

    const passwordIdentificationNumber = (data) => {
        const id = data.id;
        const email = data.email;
        const password = data.identificationNumber;

        bcrypt.genSalt(5, (err, salt) => {
            if (err == null) {
                bcrypt.hash(email + password, salt, (error, hash) => {
                    if (error == null) {
                        InsertPasswordAutomaticUser({
                            variables: {
                                id: id,
                                password: hash,
                            },
                        })
                            .then((res) => {
                                if (res.data.update_users.affected_rows > 0) {
                                    Swal.fire({
                                        title: "Registro de Contraseña Exitoso",
                                        text: "La Contraseña del Usuario fue Generada de Manera Correcta!",
                                        icon: "success",
                                        confirmButtonText: "Aceptar",
                                        willClose: () => {
                                            return;
                                        },
                                    });
                                }
                            })
                            .catch((er) => {
                                Swal.fire({
                                    title: "Error",
                                    text: `Ocurrió un error al intentar procesar los datos. Muestre la siguiente información al administrador: ${er.toString()}`,
                                    icon: "error",
                                    confirmButtonText: "Cerrar",
                                });
                            });
                    }
                });
            }
        });
    };

    const alertPasswordIdentificationNumber = (data => {
        Swal.fire({
            title: "Estás seguro?",
            text: "Se reseteará la contraseña por defecto",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sí, resetear"
        }).then((result) => {
            if (result.isConfirmed) {
                passwordIdentificationNumber(data);
            }
        });
        nullFields();
    })

    const handleSendEmailVerification = (data) => {
        const companyBrand = JSON.parse(localStorageService.get("companyDetails"));

        axios({
            method: "post",
            url: process.env.REACT_APP_FUNCTION_EMAIL_VERIFICATION_URL,
            data: {
                id: data.id,
                email: data.email,
                displayName: data.displayName,
                roleId: data.roleId,
                origin: window.location.origin,
                bigBrand: companyBrand.bigBrand,
                companyName: localStorageService.get("company"),
                primaryColor: companyBrand.primaryColor,
            },
        })
            .then((res) => {
                if (res.status === 200) {
                    Swal.fire({
                        title: "Enviado",
                        text: `El correo electrónico de verificación fue enviado.`,
                        icon: "success",
                        confirmButtonText: "Cerrar",
                        timer: 2000,
                        timerProgressBar: true,
                    });
                } else {
                    Swal.fire({
                        title: "Error",
                        text: `No fue posible enviar el correo electrónico de verificación.`,
                        icon: "error",
                        confirmButtonText: "Cerrar",
                        timer: 2000,
                        timerProgressBar: true,
                    });
                }
            })
            .catch((error) => {
                Swal.fire({
                    title: "Error",
                    text: `No fue posible enviar el correo electrónico de verificación. ${error.toString()}`,
                    icon: "error",
                    confirmButtonText: "Cerrar",
                    timer: 2000,
                    timerProgressBar: true,
                });
            });
    };

    const alertHandleSendEmailVerification = (data => {
        Swal.fire({
            title: "Estás seguro?",
            text: "Se enviara un email de verificación",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sí, enviar"
        }).then((result) => {
            if (result.isConfirmed) {
                handleSendEmailVerification(data);
            }
        });
        nullFields();
    })

    const handleActiveUser = () => {
        activeUserById({
            variables: {
                id: idUser
            },
        })
            .then((res) => {
                if (res.data.update_users.affected_rows > 0) {
                    Swal.fire({
                        title: "Activacion Exitosa",
                        text: "La activacion del usuario fue exitosa",
                        icon: "success",
                        confirmButtonText: "Aceptar",
                        willClose: () => {
                            return;
                        },
                    });
                }
            })
    }

    const alertActive = (data => {
        if (data.enabled === false) {
            Swal.fire({
                title: "Estás seguro?",
                text: "Se activara el usuario",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Sí, activar"
            }).then((result) => {
                if (result.isConfirmed) {
                    handleActiveUser();
                }
            })
        }
        else {
            Swal.fire({
                title: "Error",
                text: "Este usuario ya esta activo",
                icon: "error",
                confirmButtonText: "Cerrar",
            });
        }
        nullFields();
    })

    const validateFields = () => {
        if (name === '' || email === '' || identification === '' || role === '') {
            const errorAlert = document.getElementById("errorAlert");
            errorAlert.style.display = "block";

            setTimeout(() => {
                errorAlert.style.display = "none";
            }, 3000);
        } else {
            document.getElementById("errorAlert").style.display = "none";
            apolloClient
                .query({
                    query: checkIfIdentificationNumberEmailExists,
                    variables: { identification, email },
                })
                .then(({ data }) => {
                    if (data.users.length > 0) {
                        Swal.fire({
                            title: "Error",
                            text: "El número de identificación o el correo electronico que intenta registrar ya existe para otra cuenta, ingrese otro.",
                            icon: "error",
                            confirmButtonText: "Cerrar",
                            timer: 5000,
                            timerProgressBar: true,
                        });
                    }
                    else {
                        handleSubmit();
                    }
                })
            nullFields();
        }
    };

    const validateFieldsForUpdate = () => {
        if (name === '' || email === '' || identification === '' || role === '') {
            const errorAlert = document.getElementById("errorAlert");
            errorAlert.style.display = "block";

            setTimeout(() => {
                errorAlert.style.display = "none";
            }, 3000);
        } else {
            document.getElementById("errorAlert").style.display = "none";
            apolloClient
                .query({
                    query: checkIfIdentificationNumberEmailExistsForUpdate,
                    variables: { identification, email, id: idUser },
                })
                .then(({ data }) => {
                    if (data.users.length > 0) {
                        Swal.fire({
                            title: "Error",
                            text: "El número de identificación o el correo electronico que intenta registrar ya existe para otra cuenta, ingrese otro.",
                            icon: "error",
                            confirmButtonText: "Cerrar",
                            timer: 5000,
                            timerProgressBar: true,
                        });
                    }
                    else {
                        handleUpdate();
                    }
                })
            nullFields();
        }
    };


    return (
        <div key={refreshKey} className='selected-option'>
            <div className="user-controls">
                <div className="left">
                    <span className="title-users">Usuarios</span>
                </div>

                <div className="right">
                    <IconButton
                        style={{
                            backgroundColor: 'white',
                            padding: '10px',
                            borderRadius: '50%',
                            marginLeft: '10px',
                        }}
                        onClick={() => {
                            setUserSelected(null);
                            handleOpenCreate();
                            handleClose();
                        }}
                    >
                        <AddCircleIcon style={{ fontSize: 50, color: 'var(--primaryColor)' }} />
                    </IconButton>

                    <IconButton
                        style={{
                            backgroundColor: 'white',
                            padding: '10px',
                            borderRadius: '50%',
                            marginLeft: '10px',
                        }}
                        onClick={() => {
                            refreshList();
                        }}
                    >
                        <CachedIcon style={{ fontSize: 50, color: 'var(--primaryColor)' }} />
                    </IconButton>

                    <div className="search-users" style={{ display: 'inline-block', marginLeft: '10px' }}>
                        <TextField
                            variant="outlined"
                            value={searchedName}
                            onChange={handleSearchChange}
                            placeholder="Buscar por nombre"
                            InputProps={{
                                endAdornment: (
                                    <Button onClick={searchByName}><SearchIcon /></Button>
                                ),
                            }}
                        />
                    </div>

                    <div className="pagination-controls" style={{ display: 'inline-block', marginLeft: '10px' }}>
                        <Button onClick={handlePreviousPage} disabled={currentPage === 1}>
                            <KeyboardArrowLeftOutlinedIcon />
                        </Button>
                        <span>{currentPage}</span>
                        <Button
                            onClick={handleNextPage}
                            disabled={currentPage >= Math.ceil(userList.length / 10)}  // Assuming 10 users per page
                        >
                            <KeyboardArrowRightOutlinedIcon />
                        </Button>
                    </div>
                </div>
            </div>
            {currentItems.length > 0 && currentItems.map((user, index) => (
                <div key={index}>
                    <Grid container spacing={1} className='item-user-list'>
                        <Grid item xs={4}>
                            <span className='title-users-value-1'>{user.displayName}</span><br />
                            <span className='subtitle-users-value'>{user.email}</span>
                        </Grid>
                        <Grid item xs={2}>
                            <span className='subtitle-users-value'>Documento</span><br />
                            <span className='title-users-value'>{user.identificationNumber}</span>
                        </Grid>
                        <Grid item xs={2}>
                            <span className='subtitle-users-value'>Rol</span><br />
                            <span className='title-users-value'>{user.roleName || "Rol no disponible"}</span>
                        </Grid>
                        <Grid item xs={2}>
                            <span className='subtitle-users-value'>Fecha de creación</span><br />
                            <span className='title-users-value'>{moment(user.createdAt).format("DD/MM/YYYY")}</span>
                        </Grid>
                        <Grid item xs={1}>
                            <span className={
                                user.enabled && user.emailVerified
                                    ? 'status-user-value-green'
                                    : user.enabled && !user.emailVerified ? 'status-user-value-red' : 'status-user-value-gray'
                            }>
                                {user.enabled && user.emailVerified ? 'Activo' : user.enabled && !user.emailVerified ? 'Sin Verificar' : 'Inactivo'}
                            </span>
                        </Grid>
                        <Grid item xs={1} onClick={(event) => { handleOpen(event); setIdUser(user.id); setUserSelected(user) }}>
                            <MenuIcon />
                        </Grid>
                    </Grid>
                    <Divider className='divider-user'></Divider>
                </div>
            ))}
            {openOptions && (
                <Box
                    ref={menuRef}
                    className="modal-user-detail"
                    style={{
                        top: `${position.top - 200}px`,
                        left: `${position.left - 200}px`,
                    }}
                >
                    <p onClick={() => { alertActive(userSelected); handleClose(); }}>Activar</p>
                    <p onClick={() => { handleOpenEdit(); handleClose(); }}>Editar</p>
                    <p onClick={() => { handleDelete(userSelected); handleClose(); }}>Eliminar</p>
                    <p onClick={() => { alertPasswordIdentificationNumber(userSelected); handleClose(); }}>Resetear contraseña</p>
                    <p onClick={() => { alertHandleSendEmailVerification(userSelected); handleClose(); }}>Enviar email verificación</p>
                </Box>
            )}
            {openEdit && (
                <Box
                    ref={menuRef}
                    className="modal-user-details"
                >
                    <h2>Modificar Usuario</h2>
                    {/* Mensaje de error */}
                    <p id="errorAlert" style={{ color: 'red', display: 'none' }}>
                        <strong>Error:</strong> Faltan campos por ingresar.
                    </p>
                    <p className='textField-input'>Nombre y Apellido</p>
                    <TextField id="outlined-basic" variant="outlined" className="input-value-user" onChange={(e) => setName(e.target.value)} value={name} />
                    <p className='textField-input'>Identificación</p>
                    <TextField id="outlined-basic" variant="outlined" className="input-value-user" onChange={(e) => setIdentification(e.target.value)} value={identification} />
                    <p className='textField-input'>Correo</p>
                    <TextField id="outlined-basic" variant="outlined" className="input-value-user" onChange={(e) => setEmail(e.target.value)} value={email} />
                    <p className='textField-input'>Celular</p>
                    <TextField id="outlined-basic" variant="outlined" className="input-value-user" onChange={(e) => setPhone(e.target.value)} value={phone} />
                    <p className='textField-input'>Direccion sede</p>
                    <TextField id="outlined-basic" variant="outlined" className="input-value-user" onChange={(e) => setAddress(e.target.value)} value={address} />

                    <p className='textField-input'>Sede</p>
                    <Autocomplete
                        options={WorkshopLocationList}
                        getOptionLabel={(option) => option}
                        value={selectedWorkshopLocationList}
                        onChange={handleChangeWorkshopLocation}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                fullWidth
                                className="input-value-user"
                                disableClearable
                            />
                        )}
                        popupIcon={<CustomDropdownIcon />}
                        sx={{
                            width: "100%",
                            "& .MuiOutlinedInput-root": {
                                height: "35px",  // Asegurar que la altura sea igual a ReactSelect
                                borderRadius: "4px",  // Asegurar esquinas iguales
                            }
                        }}
                    />
                    <p className='textField-input'>Rol</p>
                    <ReactSelect
                        options={transformedRoles}
                        value={selectedOptionRole}
                        onChange={handleChangeRoles}
                    />

<p className='textField-input'>Centro de distribución</p>
                    <ReactSelect
                        className="react-select-container"
                        classNamePrefix="react-select"
                        options={transformedCenters}
                        value={selectedOptions}
                        onChange={handleChange}
                        isMulti={true}
                        styles={{
                            valueContainer: (styles) => ({
                                ...styles,
                                display: "flex",
                                flexWrap: "nowrap",
                                overflowX: "auto",
                                maxWidth: "100%",
                                justifyContent: "flex-end"
                            }),
                            multiValue: (styles) => ({
                                ...styles,
                                flexShrink: 0,
                                overflow: "hidden",
                                textOverflow: "clip",
                                whiteSpace: "nowrap",

                            }),
                        }}
                    />
                    <p className='textField-input'>Habilitado</p>
                    <input type="checkbox" onChange={(e) => setEnabled(e.target.checked)} checked={enabled} /> <br />
                    <div className="button-container">
                        <Button
                            variant="contained"
                            className="button-enviar"
                            onClick={validateFieldsForUpdate}
                        >
                            Enviar
                        </Button>
                    </div>
                </Box>
            )}
            {openCreate && (
                <Box
                    ref={menuRef}
                    className="modal-user-details"
                >
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <h2>Agregar Usuario</h2>
                        <CancelIcon style={{ fontSize: "35px", color: "red", cursor: "pointer" }} onClick={handleCloseCreate} />
                    </div>
                    {/* Mensaje de error */}
                    <p id="errorAlert" style={{ color: 'red', display: 'none' }}>
                        <strong>Error:</strong> Faltan campos por ingresar.
                    </p>

                    <p className='textField-input'>Nombre y Apellido *</p>
                    <TextField id="outlined-basic" variant="outlined" className="input-value-user" onChange={(e) => setName(e.target.value)} />
                    <p className='textField-input'>Identificación *</p>
                    <TextField id="outlined-basic" variant="outlined" className="input-value-user" onChange={(e) => setIdentification(e.target.value)} />
                    <p className='textField-input'>Correo *</p>
                    <TextField id="outlined-basic" variant="outlined" className="input-value-user" onChange={(e) => setEmail(e.target.value)} />
                    <p className='textField-input'>Celular</p>
                    <TextField id="outlined-basic" variant="outlined" className="input-value-user" onChange={(e) => setPhone(e.target.value)} />

                    <p className='textField-input'>Sede</p>
                    <Autocomplete
                        options={WorkshopLocationList}
                        // getOptionLabel={(option) => option}
                        // onChange={(event, newValue) => setSelectedWorkshopLocation(newValue)}
                        getOptionLabel={(option) => option}
                        value={selectedWorkshopLocationList}
                        onChange={handleChangeWorkshopLocation}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                fullWidth
                                className="input-value-user"
                                disableClearable
                            />
                        )}
                        popupIcon={<CustomDropdownIcon />}
                        sx={{
                            width: "100%",
                            "& .MuiOutlinedInput-root": {
                                height: "35px",  // Asegurar que la altura sea igual a ReactSelect
                                borderRadius: "4px",  // Asegurar esquinas iguales
                            }
                        }}
                    />



                    <p className='textField-input'>Direccion sede</p>
                    <TextField id="outlined-basic" variant="outlined" className="input-value-user" onChange={(e) => setAddress(e.target.value)} />
                    <p className='textField-input'>Rol *</p>
                    <ReactSelect
                        options={transformedRoles}
                        value={selectedOptionRole}
                        onChange={handleChangeRoles}
                    />
                    {/*    <Select
                        native
                        label="Age"
                        inputProps={{
                            id: 'outlined-age-native-simple',
                        }}
                        onChange={(e) => setRole(e.target.value)}
                    >
                        <option aria-label="None" value="" />
                        {roleList.map((role, index) => (
                            <option key={index} value={role.id}>{role.name}</option>
                        ))}
                            
                    </Select>*/}

                    <p className='textField-input'>Centro de distribución</p>
                    <ReactSelect
                        className="react-select-container"
                        classNamePrefix="react-select"
                        options={transformedCenters}
                        value={selectedOptions}
                        onChange={handleChange}
                        isMulti={true}
                        styles={{
                            valueContainer: (styles) => ({
                                ...styles,
                                display: "flex",
                                flexWrap: "nowrap",
                                overflowX: "auto",
                                maxWidth: "100%",
                                justifyContent: "flex-end"
                            }),
                            multiValue: (styles) => ({
                                ...styles,
                                flexShrink: 0,
                                overflow: "hidden",
                                textOverflow: "clip",
                                whiteSpace: "nowrap",

                            }),
                        }}
                    />

                    <p className='textField-input'>Habilitado</p>
                    <input type="checkbox" onChange={(e) => setEnabled(e.target.checked)} />
                    <p className='textField-input'>Validar Correo</p>
                    <input type="checkbox" onChange={(e) => setIsEmailVerified(e.target.checked)} /> <br />
                    <div className="button-container">
                        <Button
                            variant="contained"
                            className="button-enviar"
                            onClick={validateFields}
                        >
                            Enviar
                        </Button>
                    </div>
                </Box>
            )}
        </div>
    );
}
