import React, { useEffect, useState } from "react";
import {
  Grid,
  Paper,
  Typography,
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Autocomplete,
} from "@mui/material";
import WorldWide from "../../../../../../images/world-wide.png";
import { ArrowForwardIos } from "@mui/icons-material";
import { useFormData } from "../../../../../../context/FormContext";
import { fetchSectors, citiesInformation } from "../../../../queries/uploadData";
import { useLazyQuery } from "@apollo/client";
import calculateGeoEnclosure from "../../../../../../services/geo-enclosure";
import axios from "axios";
import '../styles/HeaderUploadScreenV2.css';

const TabContentThree = () => {
  const [geoClouser, setGeoClouser] = useState("");
  const [sectors, setSectors] = useState([]);

  const [getSectors, dataSectors] = useLazyQuery(fetchSectors, {
    fetchPolicy: "no-cache",
  });
  const [getCities, citiesData] = useLazyQuery(citiesInformation, {
    fetchPolicy: "no-cache",
  });

  const { formData, updateFormData } = useFormData();
  const [uniqueDepartments, setUniqueDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [filteredCity, setfilteredCity] = useState([]);
  const [selectedSector, setSelectedSector] = useState("");

  useEffect(() => {
    getCities(); // Llama a la API cuando el componente se monta
  }, []);

  useEffect(() => {
    if (citiesData.data) {
      const departments = citiesData?.data?.citiesInformation?.map(city => city.departmentName) || [];
      const uniqueDepartmentsList = [...new Set(departments)];
      setUniqueDepartments(uniqueDepartmentsList);
    }
  }, [citiesData.data]);

  useEffect(() => {
    if (selectedDepartment) {
      const cities = citiesData?.data?.citiesInformation
        .filter(city => city.departmentName === selectedDepartment)
        .map(city => city.cityName);
      setfilteredCity(cities);
    } else {
      setfilteredCity([]);
    }
  }, [selectedDepartment, citiesData.data]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "state") {
      setSelectedDepartment(value);
    }
    updateFormData({
      [name]: value,
    });
  };

  useEffect(() => {
    const loadInitData = async () => {
      getSectors();
    };
    loadInitData();
  }, []);


  const destinarionAutoComplet = async (_address, _address1, _address2) => {
    const addressDepart = _address2;
    const addressCity = _address1;
    const addressA = _address;
    const addressComplement = `${addressDepart},${addressCity},${addressA}`;
    if (_address.length > 0) {
      // verificar si no viene la latitud, longitud,
      // y si viene la dirección, convertirla a lat, lng
      let newAddress = "";
      const address = addressComplement
        .replace(",", " ")
        .replace(/\s\s+/g, " ")
        .replace("#", "")
        .split(" ");

      if (address.length > 0) {
        address.forEach((item) => {
          newAddress += item + "+";
        });
        newAddress = newAddress.substring(0, newAddress.length - 1);

        const res = await axios.get(
          `https://maps.googleapis.com/maps/api/geocode/json?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&components=country:CO&address=${newAddress}`
        );

        if (res.status === 200) {
          updateFormData({
            destination: `${res.data.results[0].geometry.location.lat},${res.data.results[0].geometry.location.lng}`
          });
        }
        let sector = calculateGeoEnclosure(
          {
            lat: res.data.results[0].geometry.location.lat,
            lng: res.data.results[0].geometry.location.lng,
          },
          dataSectors?.data?.sectors
        );
        setGeoClouser(sector);
      }
    }
  };

  return (
    <Grid container spacing={2} justifyContent="center" // Centra todo el contenido en el eje horizontal
      alignItems="center" >
      <Grid item xs={12} md={6} className="option-content-special">
        <Typography
          variant="h6"
          gutterBottom
          justifyContent="center" // Centra todo el contenido en el eje horizontal
          alignItems="center"
          sx={{
            width: "100%",
            fontWeight: "bold",
            textAlign: "center"
          }}
          className="option-content-title-client"
        >
          Destino
        </Typography>
        <Paper
          elevation={0}
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            maxWidth: "100%",
            marginTop: "10px",
            maxHeight: "481px",
            overflowY: "scroll",
            overflowX: "hidden",
          }}
        >
          <Box
            sx={{
              width: "100%",
              maxWidth: "100%",
              maxHeight: "400px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {/*Angelo Martinez AutomComplete City 03/03/2025*/}
            <FormControl
              fullWidth
              margin="normal"
              sx={{ width: "70%", height: "200px" }}
              variant="outlined"
              required
            >
              <TextField
                label="Notas"
                variant="outlined"
                size="small"
                multiline
                maxRows={2}
                name="notes"
                required
                value={formData.notes || ""}
                onChange={handleInputChange}
                error={formData.isNullFied === true && !formData.notes}
                helperText={formData.isNullFied === true && !formData.notes ? "Este campo es requerido" : ""}

              />
            </FormControl>
            {/* Campo para departamento */}
            <FormControl
              fullWidth
              margin="normal"
              sx={{ width: "70%" }}
              variant="outlined"
              required
            >
              <Autocomplete
                options={uniqueDepartments}
                getOptionLabel={(option) => option}
                value={formData.state || null}
                onChange={(_, newValue) => {
                  handleInputChange({ target: { name: "state", value: newValue || "" } });
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Departamento"
                    required
                    sx={{
                      width: "100%",
                      "& .MuiOutlinedInput-root": {
                        height: "39px",  // Asegurar que la altura sea igual a ReactSelect
                        borderRadius: "4px",  // Asegurar esquinas iguales
                      }
                    }}
                    size="small"
                    error={formData.isNullFied === true && !formData.state}
                    helperText={formData.isNullFied === true && !formData.state ? "Este campo es requerido" : ""}
                  />
                )}

              />
            </FormControl>

            {/* Campo para ciudad */}
            <FormControl
              fullWidth
              margin="normal"
              sx={{ width: "70%" }}
              variant="outlined"
              required
            >
              <Autocomplete
                options={filteredCity}
                getOptionLabel={(option) => option}
                name="city"
                value={formData.city || ""}
                onChange={(_, newValue) => {
                  handleInputChange({ target: { name: "city", value: newValue || "" } });
                }}
                renderInput={(params) => (
                  <TextField {...params}
                    label="Ciudad"
                    required
                    sx={{
                      width: "100%",
                      "& .MuiOutlinedInput-root": {
                        height: "39px",  // Asegurar que la altura sea igual a ReactSelect
                        borderRadius: "4px",  // Asegurar esquinas iguales
                      }
                    }}
                    size="small"

                    error={formData.isNullFied === true && !formData.city}
                    helperText={formData.isNullFied === true && !formData.city ? "Este campo es requerido" : ""}
                  />
                )}
                disabled={!formData.state}
              />
            </FormControl>
            {/*  
            Comentario codigo original Angelo Martinez 
            <TextField
              label="Departamento"
              variant="outlined"
              sx={{ width: "70%", marginTop: "160px" }}
              margin="normal"
              name="state"
              value={formData.state || ""}
              onChange={handleInputChange}
            />
            
            <TextField
              label="Ciudad"
              variant="outlined"
              sx={{ width: "70%" }}
              margin="normal"
              name="city"
              value={formData.city || ""}
              onChange={handleInputChange}
            />
            */}

            {/* Campo para dirección de entrega */}
            <FormControl
              fullWidth
              margin="normal"
              sx={{ width: "70%" }}
              variant="outlined"
              required
            >
              <TextField
                label="Dirección de entrega"
                required
                name="address"
                size="small"
                value={formData.address || ""}
                onChange={(e) => {
                  handleInputChange(e);
                  destinarionAutoComplet(e.target.value, formData.city, formData.state);
                }}
                disabled={!formData.city}
                error={formData.isNullFied === true && !formData.address}
                helperText={formData.isNullFied === true && !formData.address ? "Este campo es requerido" : ""}
              />
            </FormControl>

            {/* campo para complemeto de direccion */}
            <FormControl
              fullWidth
              margin="normal"
              sx={{ width: "70%" }}
              variant="outlined"
              required
            >
              <TextField
                label="Complemento de dirección"
                size="small"
                name="addressComplement"
                value={formData.addressComplement || ""}
                onChange={handleInputChange}
                disabled={!formData.address}
                
              />
            </FormControl>

            {/* campo para complemeto de direccion */}
            <FormControl
              fullWidth
              margin="normal"
              sx={{ width: "70%", height: "100%" }}
              variant="outlined"
              required
              size="small"
              
            >
              <TextField
                label="Latitud y longitud"
                disabled
                value={formData.destination}
                name="destination"
                onChange={handleInputChange}
                size="small"
                error={
                  (formData.isNullFied === true && !formData.destination) || 
                  (formData.destination === undefined)
                }
                helperText={
                  formData.destination === undefined
                    ? "El valor es undefined"
                    : formData.isNullFied === true && !formData.destination
                    ? "Este campo es requerido"
                    : ""
                }
                
              />
            </FormControl>

            <FormControl
              fullWidth
              margin="normal"
              sx={{ width: "70%", height: "100%" }}
              variant="outlined"
              required
              error={formData.isNullFied === true && !formData.sectorId}
              helperText={formData.isNullFied === true && !formData.sectorId ? "Este campo es requerido" : ""}
              size="small"

            >
              <InputLabel id="zona-label">Zona</InputLabel>
              <Select
                labelId="zona-label"
                id="zona"
                label="Zona"
                fullWidth
                
                value={formData.sectorId || geoClouser?.id || ""}
                onChange={(event) => {
                  const selectedSector = dataSectors?.data?.sectors.find(sector => sector.id === event.target.value);

                  setSelectedSector(selectedSector?.name || "");
                  handleInputChange({
                    target: { name: "sectorId", value: selectedSector ? selectedSector.id : "" },
                  });
                }}
                sx={{
                  width: "100%",
                  textAlign: "left",
                  justifyContent: "center",
                  "& .MuiSelect-select": {
                    textAlign: "left", 
                    justifyContent: "center",
                  },
                }}
                size="small"
              >
                {dataSectors?.data?.sectors?.map((sector) => (
                  <MenuItem
                    sx={{
                      minWidth: "345px",
                    }}
                    key={sector.id} value={sector.id}>
                    {sector.name}
                  </MenuItem>
                ))}
              </Select>

              {geoClouser?.name && selectedSector && geoClouser?.name !== selectedSector ? (
                <Box sx={{ color: "red", marginTop: "5px", fontSize: "0.9rem" }}>
                  La ubicación se encuentra dentro de la zona <strong>{geoClouser?.name}</strong>
                  pero esta no es la seleccionada.
                </Box>
              ) : null}
            </FormControl>


            <FormControl
              fullWidth
              margin="normal"
              sx={{ width: "70%" }}
              variant="outlined"
              size="small"

            >
              <InputLabel id="prioridad-label">Prioridad</InputLabel>
              <Select
                labelId="prioridad-label"
                id="prioridad"
                label="Prioridad"
                name="priority"
                value={formData.priority || ""}
                onChange={handleInputChange}
                sx={{
                  width: "100%",
                  "& .MuiOutlinedInput-root": {
                    height: "39px",
                    borderRadius: "4px",
                  },
                  textAlign: "left",
                  "& .MuiSelect-select": {
                    textAlign: "left", 
                  },
                }}
                size="small"
              >
                <MenuItem value="a_high">Alto</MenuItem>
                <MenuItem value="b_medium">Medio</MenuItem>
                <MenuItem value="c_low">Bajo</MenuItem>
              </Select>
            </FormControl>


          </Box>
        </Paper>
      </Grid>

      <Grid
        className="option-content"
        item
        xs={12}
        md={6}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box sx={{ maxWidth: "100%", textAlign: "center" }}>
          <img
            src={WorldWide}
            alt="WorldWide"
            className="option-contentImage"

          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default TabContentThree;